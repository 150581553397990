import Vue from 'vue'
import VueRouter from 'vue-router'
import * as auth from '@/utils/auth'
// import store from '@/store'
import { Message } from 'element-ui';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home'
    }
  },
  // 首页
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Comprehensive',
    name: 'Comprehensive',
    component: () => import('../views/Comprehensive/index.vue')
  },
  {
    path: '/ComprehensiveInputForm',
    name: 'ComprehensiveInputForm',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/Comprehensive/inputForm.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/Register/InputForm/index.vue')
  },
  {
    path: '/Register/list',
    name: 'RegisterList',
    component: () => import('../views/Register/list.vue')
  },
  {
    path: '/Register/detail',
    name: 'RegisterDetail',
    component: () => import('../views/Register/detail.vue')
  },
  {
    path: '/ExistingEvidence',
    name: 'ExistingEvidence',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/ExistingEvidence/InputForm/index.vue'),
    children:[
      // 我的存证-查看存证文件
      {
        path:'FileList',
        name:'FileList',
        meta:{auth:true},
        component: () => import('../views/ExistingEvidence/FileList.vue'),
      },
    ]
  },
  {
    path: '/Evaluate',
    name: 'Evaluate',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/Evaluate/index.vue'),
    children:[
      // 主页
      {
        path:'',
        name:'EvaluateMain',
        meta:{auth:true},
        component: () => import('../views/Evaluate/evaluate.vue'),
      },
      // 相关介绍页面
      {
        path:'About',
        name:'EvaluateAbout',
        meta:{auth:true},
        component: () => import('../views/Evaluate/about.vue'),
      },
    ]
  },



  // 钱包》订单详情
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: () => import('../views/Mine/MoneyBag/OrderDetail.vue')
  },
  // 钱包》充值记录》充值详情
  {
    path: '/WithdrawalDetail',
    name: 'WithdrawalDetail',
    component: () => import('../views/Mine/MoneyBag/WithdrawalDetail.vue')
  },

  /**
   * 个人中心系列
   */
  {
    path: '/Mine',
    name: 'Mine',
    component: () => import('../views/Mine/index.vue'),
    children:[
      // 个人信息
      {
        path:'BaseInfo',
        name:'BaseInfo',
        meta:{auth:true},
        component: () => import('../views/Mine/BaseInfo/index.vue'),
      },
      // 实名认证
      {
        path: 'RealName',
        name: 'RealName',
        meta:{auth:true},
        component: () => import('../views/Mine/RealName/index.vue'),
      },
      // 钱包
      {
        path: 'MoneyBag',
        name: 'MoneyBag',
        meta:{auth:true},
        component: () => import('../views/Mine/MoneyBag/index.vue'),
      },
      // Vip
      {
        path: 'Vip',
        name: 'MineVip',
        meta:{auth:true},
        component: () => import('../views/Mine/Vip/index.vue'),
      },
      // 我的存证
      {
        path: 'ExistingEvidence',
        name: 'MineExistingEvidence',
        meta:{auth:true},
        component: () => import('../views/Mine/ExistingEvidence/index.vue'),
      },
       // 我的登记
       {
        path: 'MyRegister',
        name: 'MyRegister',
        meta:{auth:true},
        component: () => import('../views/Mine/MyRegister/index.vue'),
      },
      // 我的查重
      {
        path: 'MyCheck',
        name: 'MyCheck',
        meta:{auth:true},
        component: () => import('../views/Mine/MyCheck/index.vue'),
      },
      // DCI申领
      {
        path: 'MyDCI',
        name: 'MyDCI',
        meta:{auth:true},
        component: () => import('../views/Mine/MyDCI/index.vue'),
      },
      // DCI申领详情
      {
        path: 'DCIDetail',
        name: 'DCIDetail',
        meta:{auth:true},
        component: () => import('../views/Mine/MyDCI/DCIDetail.vue'),
      },
      // 消息通知
      {
        path: 'Notice',
        name: 'Notice',
        meta:{auth:true},
        component: () => import('../views/Mine/Notice/index.vue'),
      },
      // 我的评价
      {
        path: 'MyEvaluate',
        name: 'MyEvaluate',
        meta:{auth:true},
        component: () => import('../views/Mine/MyEvaluate/index.vue'),
      },
      // 我的详情详情
      {
        path: 'EvaluateDetail',
        name: 'EvaluateDetail',
        meta:{auth:true},
        component: () => import('../views/Mine/MyEvaluate/EvaluateDetail.vue'),
      },
    ]
  },

  // 我的存证-详情
  {
    path:'/ExistingEvidence/Detail',
    name:'ExistingEvidenceDetail',
    meta:{auth:true},
    component: () => import('../views/ExistingEvidence/Detail.vue'),
  },

  // 登录
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/loginRigist/Login.vue')
  },
  // 注册
  {
    path: '/Rigist',
    name: 'Rigist',
    component: () => import('../views/loginRigist/Rigist.vue')
  },
  // 重置密码
  {
    path: '/ForgetPwd',
    name: 'ForgetPwd',
    component: () => import('../views/loginRigist/ForgetPwd.vue')
  },
  // 注册协议
  {
    path: '/Agreement',
    name: 'Agreement',
    component: () => import('../views/loginRigist/Agreement.vue')
  },

  // 论文查重
  {
    path: '/DuplicateCheck',
    name: 'DuplicateCheck',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/DuplicateCheck/index.vue')
  },
  // 查重详情
  {
    path: '/DuplicateCheck/detail',
    name: 'DuplicateCheckDetail',
    component: () => import('../views/DuplicateCheck/detail.vue')
  },
  {
    path: '/DuplicateCheck/contrast',
    name: 'DuplicateCheckContrast',
    meta:{noShowHeader: true},
    component: () => import('../views/DuplicateCheck/contrast.vue')
  },

  // 科研诚信
  {
    path: '/Scientific',
    name: 'Scientific',
    component: () => import('../views/Scientific/index.vue')
  },
  {
    path: '/ScientificList',
    name: 'ScientificList',
    component: () => import('../views/Scientific/list.vue')
  },
  {
    path: '/ScientificDetail',
    name: 'ScientificDetail',
    component: () => import('../views/Scientific/detail.vue')
  },
  //关于我们
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  //DCI申领
  {
    path: '/DCI',
    name: 'DCI',
    component: () => import('../views/DCI/index.vue')
  },
  //DCI申领表单
  {
    path: '/DCIInputForm',
    name: 'DCIInputForm',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/DCI/inputForm.vue')
  },
  // DCI申领数登申请表单
  {
    path: '/DCIRegisterInputForm',
    name: 'DCIRegisterInputForm',
    meta:{auth:true,authInfo: true},
    component: () => import('../views/DCI/DCIRegisterInputForm.vue'),
  },

]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // ...
  //console.log(to)
  //console.log(to.meta)
  if(to.meta.auth){
    //需要登录
    // console.log('auth.getAuthInfo()',auth.getAuthInfo());
    if(auth.getMid()){
        if(to.meta.authInfo){  // 需要实名认证 
          if(auth.getAuthInfo()){
            // console.log('已实名');
            next();
          }else{
            Message({
              type: 'warning',
              message: '请先完成实名认证！'
            });
            next({
              path: '/Mine/RealName',
              query: { redirect: to.fullPath }
            })
          }
        }else{
          next();
        }
    }else{
      next({
        path: '/Login',
        query: { redirect: to.fullPath }
      })
    }
  }else{
    next()
  }
})

export default router
