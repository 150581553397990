<template>
  <div>
    <div class="footMenu">
      <div class="container">
        <a-row>
            <a-col :md="24" :lg="10" :xl="10">
              <img src="../assets/images/logo_b.png" class="logo_b" alt="">
              <p class="logo_text"><span>中宣部版权管理局试点任</span>务</p>
            </a-col>
            <a-col :md="24" :lg="3" :xl="3">
                <div>版权服务</div> 
                <router-link :to="{path:'/Register'}"> 版权登记 </router-link>
                <router-link :to="{path:'/Register/list'}"> 证书查询 </router-link>
                <router-link :to="{path:'/DuplicateCheck'}"> 学术检测 </router-link>
                <router-link :to="{path:'/Scientific'}"> 科研诚信 </router-link>
            </a-col>
            <a-col :md="24" :lg="6" :xl="6">
              <div>主办单位</div>
              <p>中文出版物知识产权评价中心</p>
              <div style="margin-top: 20px;">合作单位</div>
              <p>广东万方数据信息科技有限公司</p>
            </a-col>
            <!-- <a-col :md="24" :lg="5" :xl="5">
              <div>友情链接</div>
              <a href="http://www.chinashukan.com/" target="_blank">书刊网</a>
            </a-col> -->
            <a-col :md="24" :lg="5" :xl="5">
                <div>联系我们</div>
                <p>地址：武汉市洪山区珞珈山路19号</p>
                <p>电话：027 - 87638981</p>
                <p>邮箱：service@cpipip.cn</p>
                <p>网址：https://dci.cpipip.com/Home</p>
            </a-col>
        </a-row>
      </div>
    </div>
    <div class="foot">
      <p><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023006073号</a><img src="../assets/images/footIcon.jpg" /><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102005181" target="_blank">鄂公网安备 42011102005181号</a></p>
      <p>国家互联网信息办公室区块链信息服务备案编号：<a href="https://bcbeian.ifcert.cn/" target="_blank">鄂网信备42011122891907500013号</a></p>
      <p>Copyright © 2023 中文出版物知识产权评价中心 All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FootBlock',

  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss'>
    .footMenu{
      background: #1B1B1B;
      padding:20px 0;
      .logo_b{
        height: 42px;
        margin-top: 20px;
      }
      .logo_text{
        border-top: 1px solid #FFFFFF;
        font-size: 14px;
        color: #A7A7A7;
        line-height: 42px;
        margin-top: 16px;
        display: inline-block;
        span{
          letter-spacing: 19px;
        }
      }
      div{
        font-size: 14px;
        color: #8F8F8F;
        line-height: 20px;
        margin-bottom: 6px;
      }
      a,p{
        color: #ECECEC;
        display: block;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .foot{
        width: 100%;
        background: #202020;
        text-align: center;
        padding:20px 0;
        .links{
           color:#999;
            a{
                color:#999;
                margin:0 8px;
                text-decoration: none;
                opacity: 0.9;
            }
            a:hover{
              opacity: 1;
            }
        }
        .copyRight{
          color:#999;
          border-top:solid 1px rgba(0,0,0,0.1);
          padding-top:24px;
          margin-top:24px;
        }
        .text-light-gray{
          color:#999;
        }
        p{
          display: flex;
          justify-content: center;
          align-items: center;
          color: #8F8F8F;
          a{
            color: #8F8F8F;
            text-decoration: none;
          }
          img{
            width: 15px;
            height: 15px;
            margin: 0 5px;
          }
        }
    }
</style>
