<template>
<div class="header">
  <div class="commonHead" :class="{'opened':opened}">
    <div class="container headWrap">
         <a-icon type="menu" class="menuIcon" id="openBtn" @click="opened=!opened" />
         <!-- <a-icon type="menu" class="menuIcon" id="openBtn" @click="opened=!opened"  v-if="!opened"/>
         <a-icon type="close" class="menuIcon" v-else/> -->
         <div class="manueWrap">
             <router-link :to="{path:'/Home'}">
              <img class="logo" src="../assets/images/logo.png" alt="">
             </router-link>
         </div>
         <div class="outUl_div">
            <div class="container">
              <ul class="outUl">
                <li v-for="(item,index) in manue" :key="item.key" :class="{'active':activeIdx===item.key || isDCI && item.key=='DCI' || isComprehensive &&  item.key=='Comprehensive','liOpened':item.childOpened}" @click="outerTiggle(index)" event="mouseover" :default-active="getActive()">
                  <div>{{item.label}}
                    <a-icon v-if="!!item.children" type="down"/>
                  </div>
                  <ul v-if="!!item.children"  class="ant-dropdown-menu">
                      <li v-for="(itemChild,indexChild) in item.children" :key="indexChild" class="ant-dropdown-menu-item" @click="goto(itemChild)">{{itemChild.label}}</li>
                  </ul>
                </li>
                <!-- <li><div><a href="http://www.chinashukan.com/" target="_blank">书刊网</a></div></li> -->
              </ul>
            </div>
          </div>
         <div class="rightWrap">
           <div class="rightTop">
              <template v-if="userInfo!=null">
                  <div class="userWrap">
                      <i class="icon iconfont">&#xe64d;</i>
                      <a @click="goto({name:'个人中心',router:'/Mine/BaseInfo',key:'MineBaseInfo'})"> {{userInfo.mobile}} </a><span class="logout2" @click="onLogout" style="margin-left: 10px;">退出</span>
                      <a-icon class="arrowDown" type="caret-down" />
                      <ul class="ant-dropdown-menu detailLayout">
                        <li v-for="(item,index) in centerLink" :key="index" class="ant-dropdown-menu-item" @click="goto(item)"> {{item.label}}</li>
                        <li class="ant-dropdown-menu-item" @click="onLogout">退出</li>
                      </ul>
                  </div>
              </template>
              <template v-else>
                <a-button class="login" @click="goto({name:'登录',router:'/Login',key:'Login'})">
                  登录
                </a-button>
                  <a-button class="sign" @click="goto({name:'注册',router:'/Rigist',key:'Rigist'})">
                  注册
                </a-button>
              </template>
           </div>
            <div class="clearfix"></div>
         </div>
     </div>
     <!-- <div class="outUl_div" style="display: none;">
       <div class="container">
         <ul class="outUl">
          <li v-for="(item,index) in manue" :key="item.key" :class="{'active':activeIdx===item.key,'liOpened':item.childOpened}" @click="outerTiggle(index)" event="mouseover">
            <div>{{item.label}}
              <a-icon v-if="!!item.children" type="down"/> 
            </div>
            <ul v-if="!!item.children"  class="ant-dropdown-menu">
                <li v-for="(itemChild,indexChild) in item.children" :key="indexChild" class="ant-dropdown-menu-item" @click="goto(itemChild)">{{itemChild.label}}</li>
            </ul>
          </li>
        </ul>
       </div>
     </div> -->
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {logout} from '@/api/UserService'
export default {
  name: 'HeadBlock',
  props: {
    // 当前激活导航
    activeIdx: {
      type: [Number,String],
      default: 0
    }
  },
  data () {
    return {
      dialogVisible:false,
      logined:true,
      opened:false,
      liOpenedCurrent: false,
      time:'',
      manue: [
        {
          label:'首页',
          router:'/Home',
          key:'Home'
        },
        {
          label:'综合服务',
          router:'/Comprehensive',
          key:'Comprehensive'
        },
        {
            label:'版权服务',
            router:'/DuplicateCheck',
            childOpened:false,
            key:'',
            children:[
              {
                label: '论文查重',
                router: '/DuplicateCheck',
                key: 'DuplicateCheck'
              },
              {
                label: 'DCI申领',
                router: '/DCI',
                key: 'DCI'
              },
              {
                label:'版权存证',
                router:'/ExistingEvidence',
                key:'ExistingEvidence'
              },
              {
                label:'版权登记',
                router:'/Register',
                key:'Register'
              },
              {
                label:'中文评价',
                router:'/Evaluate',
                key:'Evaluate'
              },
            ]
        },
        {
          label:'证书查询',
          router:'/Register/list',
          key:'RegisterList'
        },
        // {
        //   label:'数字存证',
        //   router:'/ExistingEvidence',
        //   key:'ExistingEvidence'
        // },
        {
          label: '科研诚信',
          router: '/Scientific',
          key: 'Scientific'
        },
        {
          label: '关于我们',
          router: '/AboutUs',
          key: 'AboutUs'
        },
        
      ],

      centerLink:[
        {
           label:'个人信息',
            router:'/Mine/BaseInfo',
            key:'MineBaseInfo'
        },
        {
           label:'实名认证',
            router:'/Mine/RealName',
            key:'MineRealName'
        },
        // {
        //    label:'著作权人管理',
        //     router:'/Mine/Obligee',
        //     key:'Mine'
        // },
        // {
        //    label:'我的钱包',
        //     router:'/Mine/MoneyBag',
        //     key:'MineObligee'
        // },
        {
           label:'我的存证',
            router:'/Mine/ExistingEvidence',
            key:'MineExistingEvidence'
        },
        {
           label:'我的查重',
            router:'/Mine/MyCheck',
            key:'MineMyCheck'
        },
        {
           label:'我的DCI',
            router:'/Mine/MyDCI',
            key:'MineMyDCI'
        },
        {
           label:'我的登记',
            router:'/Mine/MyRegister',
            key:'MineMyRegister'
        },
        {
           label:'我的评价',
            router:'/Mine/MyEvaluate',
            key:'MineMyEvaluate'
        },
        // {
        //    label:'我的监测',
        //     router:'/Mine/Monitor',
        //     key:'MineMonitor'
        // },
        // {
        //    label:'我的取证',
        //     router:'/Mine/ObtainEvidence',
        //     key:'MineObtainEvidence'
        // },
        // {
        //    label:'合约管理',
        //     router:'/Mine/OnSale',
        //     key:'MineOnSale'
        // }
      ],

      scrollTopNum: "",//页面滚动的高度
      tabshow: true,//是否进行某种操作

      isDCI: false,
      isComprehensive: false,

    }
  },
  created(){
    this.refreshTime();
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // 移动端导航，点击空白地方，隐藏导航
    document.addEventListener('click',(e)=>{
      if(document.getElementById('openBtn') && !document.getElementById('openBtn').contains(e.target)){
        // console.log(555,this.opened);
          this.opened = false;
      }
    })
  },
  methods: {
    // DCI申领页面，同样给导航DCI的active状态
    getActive:function(){
      if(this.$route.path === '/DCIInputForm'){
        this.isDCI = true;
      }else{
        this.isDCI = false;
      }
      if(this.$route.path === '/ComprehensiveInputForm'){
        this.isComprehensive = true;
      }else{
        this.isComprehensive = false;
      }
    },

    handleScroll() {
      let top =
        document.documentElement.scrollTop ||document.body.scrollTop ||window.pageYOffset;
      this.scrollTopNum = top;
    },

    outerTiggle(idx){
        const item= this.manue[idx]
        this.manue[idx].childOpened= !item.childOpened
        if(!item.children){
            this.goto(item)
        }

        if(item.key == 'Cnpkp'){
          window.open("https://www.cnpkp.com/Home", '_blank');
        }
    },
    addLIOpened(){
      this.liOpenedCurrent = !this.liOpenedCurrent;
    },
    goto(item){
      // const windowWidth=document.body.clientWidth 
      // if(windowWidth<992&&(item.key=="MineBaseInfo"||item.key=="ExistingEvidenceInputForm")){
      //   this.dialogVisible=true
      //   return
      // }
      this.$router.push({
            path: item.router,
            query: {}
          });
      this.dialogVisible=false
    },
    // 外部跳链
    // goPaper(item){
    //   if(item == '论文首发'){
    //     window.open("https://paper.cnpkp.com/",'_blank')
    //   }
    // },
    refreshTime(){
      let that=this;
      let refreshTimeText=()=>{
        that.time=this.dayjs().format('YYYY-MM-DD HH:mm:ss:SSS ');
      };
      setInterval(refreshTimeText,100);
    },
    onLogout(){
      let that=this;
       logout().then(({data,res})=>{
          that.$store.commit('logout');
          window.location.replace(window.location.protocol + "//"+window.location.host)
      });
    },
  },
  watch: {
    scrollTopNum: function () {
      if (this.scrollTopNum > 32) {
        this.tabshow = false
      } else {
        this.tabshow = true;
      }
    },
  },

}
</script>

<style lang='scss' scoped>
.header{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0px 2px 10px 0px rgba(3,5,85,0.2);
  background: #fff;
}
._1smz4_vxa2BbZ3XcW0wq0C {
    width: 100%;
    height: 32px;
    background-color: #fafafa;
    z-index: 3;
    position: relative;
    display: none!important;;
}
._cIlG6H_ICuEq6iP5XxMR {
    width: 1160px;
    height: 32px;
    line-height: 24px;
    font-size: 12px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    li {
        color: #777;
        padding-left: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
            margin-right: 4px;
        }
    }
}
.headTop{
  background: #04397F;
    .container{
         color:#fff;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
          color:#fff;
        }
    }
  
}
  .commonHead{
      color:rgba(231, 202, 202, 0.75);
      font-size: 16px;
      height: 60px;
     .headWrap{
      //  display: flex;
      //  justify-content: space-between;
      //  align-items: center;
          display: flex;
          align-items: center;
     }
     .outUl_div{
       float: left;
       height: 60px;
       flex: 1;
       .outUl{
          flex: 1;
          display: flex;
            align-items: center;
            justify-content: flex-end;
              &>li{
                position: relative;
                height: 60px;
                line-height: 60px;
                text-align: center;
                &>div{
                  cursor: pointer;
                  line-height: 60px;
                  color: #333;
                  letter-spacing: 2px;
                  font-size: 16px;
                  padding: 0 10px;
                  // font-weight: bold;
                }
                ul{
                  position: absolute;
                  left: 0px;
                  top:60px;
                  display: none;
                  text-align: center;
                  z-index: 1000;
                  border-radius: 2px;
                }
                li{
                  letter-spacing: 2px;
                  color: #333;
                  font-size: 14px;
                  padding: 8px 18px;
                }
                ul.userUl{
                  li{
                    padding: 5px 18px;
                  }
                }
                li:hover{
                  background: #E5EBF2;
                }
              }
              &>li:hover{
                div{
                  opacity: 0.8;
                }
                ul{
                  display: block;
                }
              }
              .active {
                background: linear-gradient(to bottom, rgba(16, 65, 137, .2), transparent);
                // border-top: 2px solid #104189;
                position: relative;
                div{
                  // font-weight: bold;
                  color:#104189;
                }
              }
              .active::before{
                content: '';
                width: 100%;
                height: 2px;
                background: #104189;
                position: absolute;
                left: 0;
                top: 0;
              }
              .anticon{
                font-size: 8px;
                position: absolute;
                top: 28px;
                right: 2px;
              }
            }
     }
     .menuIcon{
       display: none;
       line-height: 44px;
       font-size: 24px;
       font-weight: bold;
     }
      .manueWrap{
        float: left;
        height: 60px;
        display: flex;
        align-items: center;
        padding:10px 0;
        a{
          text-decoration:none;
          .logo{
            height: 38px;
          }
        }
        
      }
        .rightWrap{
          float: right;
          height: 60px;
          .clearfix{
            clear: both;
          }
          .rightTop{
            float: right;
            height: 60px;
            display: flex;
            align-items: center;
            padding: 10px 0;
            .logout2{display: none;}
            &>*{
          }
          .anticon{
            font-size: 16px;
            color:#333;
          }
          /deep/ .ant-btn-background-ghost:hover, /deep/ .ant-btn-background-ghost:focus{
              color:rgba(0, 206, 206,.75);
              border-color:rgba(0, 206, 206,.75);
          }
            .searchIputDiv{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .searchIput{
                border-color: #0A6ACF;
                height: 38px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
              .searchIputBtn{
                background: #0A6ACF;
                width: 120px;
                height: 38px;
                text-align: center;
                text-decoration: none;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                img{
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-right: 5px;
                  margin-top: -3px;
                }
                span{
                  display: inline-block;
                  color: #fff;
                  vertical-align: middle;
                  line-height: 38px;
                }
              }
            }
            button{
              display: block;
                height: 38px;
                color: #333;
                background: transparent;
                border: none;
                margin-left: 0;
                font-size: 14px;
            }
            .login{
              position: relative;
            }
            .login::after{
              content: '';
              width: 1px;
              height: 16px;
              background: #333;
              position: absolute;
              top: 12px;
              right: 0px;
            }
          }
        }

        .userWrap{
          display: flex;
          align-items: center;
          position: relative;
          color: #333;
          height: 60px;
          i{
            font-size: 18px;
            margin-right: 8px;
            color: #333;
          }
          span{
            color: #0A6ACF;
          }
          a{
            text-decoration: none;
            // color: #0A6ACF;
            color: #333;
            font-size: 16px;
          }
          .anticon{
            font-size: 14px;
            margin-left: 4px;
            svg{
              // color: #0A6ACF;
              color: #333;
            }
          }
          .detailLayout{
            display: none;
            position: absolute;
            top: 60px;
            right:0;
            text-align: center;
            z-index: 1001;
            li{
              padding: 5px 18px;
            }
            li:hover{
              background: #F2F8FF;
            }
          }
        }
        .userWrap:hover{
          .detailLayout{
            display: block;
          }
        }
  }
  
@media (min-width:992px)  {
  .commonHead {
      .rightWrap{
        .sign,.login{
            
        } 
      }
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px){
  .headTop .container{
    justify-content: center;
    .callUs{
      display: none;
    }
  }
  .header{
    height: 60px;
  }
  .commonHead {
    height: 60px;
      .headWrap{
        display: block;
        height: 60px;
        min-height: 60px;
        padding:10px 0;
        position: relative;
         .outUl{
            display: none;
            width:100%;
             li{
               &>div:hover{
                  background: rgba(255,255,255,.1);
                }
             }
          }
      }
      .menuIcon{
        display: block;
        position: absolute;
        right:10px;
        top:12px;
        color: #65686C;
        cursor: pointer;
      }
      .logo{
        position: absolute;
        left: 10px;
        top: 17px;
        height: 30px!important;
      }
       .rightWrap{
            display: none;
            padding: 0 16px;
           .sign,.login{
            display: block;
            } 
        }
      .userWrap{
        .arrowDown{
          display: none;
        }
      }
      .userWrap:hover{
          .detailLayout{
            display: none;
          }
      }
  }
  .outUl_div{
    display: none;
  }
  .opened{
    position: relative;
    .rightWrap{
      // display: none;
      display: block;
      justify-content: flex-end;
      width: 100%;
      height: auto!important;
      // background: #FFFFFF;
      background: linear-gradient(270deg, #FEFEFE 0%, #F4F6F8 100%);
      box-shadow: 0px 2px 10px 0px rgba(0, 109, 117, 0.06);
      z-index: 99;
      .rightTop{
        padding-bottom: 0;
        .logout2{display: block;}
      }
    }
    .manueWrap{
      justify-content: flex-end;
    }
    .outUl_div{
      display: block;
      width: 100%;
      height: auto!important;
      background: linear-gradient(270deg, #FEFEFE 0%, #F4F6F8 100%);
      // box-shadow: 0px 2px 10px 0px rgba(0, 109, 117, 0.06);
      z-index: 99;
      margin-top: 50px;
        .outUl{
          display: block;
          text-align: right;
          margin-top: 0;
          padding: 10px 0 0 0;
        &>li{
          height: auto!important;
          border-bottom: 1px solid #E8EDF1;
            div{
              color: #333;
              line-height: 40px;
              text-align: right;
            }
            .anticon{
              top: 16px;
            }
            ul{
              position: static;
              background: none;
              box-shadow: none;
              border-top:solid 1px rgba(255, 255, 255,.1);
              border-bottom:solid 1px rgba(255, 255, 255,.1);
              border-radius: 0;
              li{
                // color:rgba(255, 255, 255,.5);
                color: rgba(0,0,0,0.6);
                font-size: 14px;
                text-align: right;
              }
              li:hover{
                background: rgba(255,255,255,.1);
              }
            }
        } 
        &>li:hover{
            ul{
              display: none;
            }
        }
        .active {
          background: none;
                div{
                  color: #3377fe;
                  font-weight: bold;
                }
              }
              .active::before{
                display: none;
              }
        &>li.liOpened{
          ul{
            display: block;
          }
        }
        }
    }
  }
}

</style>
